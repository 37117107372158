import React from "react";
import MUIDataTable from "mui-datatables";
import InputCaptureForm from "../modules/actions/InputCaptureForm";

export default function FilterTableWithDialog({ open, setOpen, captureState, setCaptureState, accept, columns, data, title, options }) {
  return (
    <>
        <InputCaptureForm
            open={open}
            setOpen={setOpen}
            captureState={captureState}
            setCaptureState={setCaptureState}
            accept={accept}
        />

        <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            title={title}
        />
    </>
  );
}
