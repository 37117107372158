import React, { useEffect, useState } from "react";
import {
  AppHeaderComponent,
  PurchasingApi,
  CodesApi,
  PersonnelApi,
} from "@unity/components";
import Loading from "../common/Loading";
import RequestForm from "../modules/requests/RequestForm";

export default function PurchasingView({ context, route }) {
  const [data, setData] = useState(false);
  const [costings, setCostings] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [periodicCodes, setPCs] = useState([]);
  const [personnel, setPersonnel] = useState([]);
  const [errors, setErrors] = useState({});
  const [prodFocus, setProdFocus] = useState(false);

  const getRequisitionSingleData = async () => {
    const res = await PurchasingApi.getRequisitionSingle(route.match.params.id);
    setData(res.data);
  };

  const getCostings = async () => {
    const res = await PurchasingApi.getApprovedCostings();
    setCostings(res.data);
  };

  const getBusinesses = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinesses(res.data);
  };

  const getPCss = async () => {
    const res = await CodesApi.getPeriodicCodes();
    setPCs(res.data);
  };

  const getPersonnel = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const handleUpload = (e) => {};

  const handleChange = (name, value) => {};

  useEffect(() => {
    getRequisitionSingleData();
    getCostings();
    getBusinesses();
    getPCss();
    getPersonnel();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="purchasing"
          subpage="view"
        />
        <div style={{ paddingTop: 80, paddingBottom: 40 }}>
          <RequestForm
            data={data}
            errors={errors}
            prodFocus={prodFocus}
            type="view"
            costings={costings}
            businesses={businesses}
            periodicCodes={periodicCodes}
            personnel={personnel}
            handleChange={handleChange}
            handleUpload={handleUpload}
            bg={context.theme.sidebar.background}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
