import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import "./styles.css";
import { SuppliersApi } from "@unity/components";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateSupplierForm({
  getSuppliersData,
  openSupplier,
  setOpenSupplier,
  bg,
}) {
  const [supplierData, setData] = useState({});
  const [supplierErrors, setErrors] = useState({});

  const handleChange = (name, value) => {
    const newData = { ...supplierData };
    newData[name] = value;
    setData(newData);
  };

  const handleSave = async () => {
    const x = await SuppliersApi.saveSuppliers(supplierData);

    if (x.errors) {
      setErrors(x.errors);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    getSuppliersData();
    setOpenSupplier({ status: false });
  };

  useEffect(() => {}, []);

  return (
    <div style={{ width: 500, maxWidth: "auto" }}>
      <Dialog
        open={openSupplier.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ maxWidth: 1000 }}>
          <div className="block">
            <div className="container-fluid">
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <TextField
                    label="Name"
                    value={supplierData.name}
                    name="name"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                    error={supplierErrors.name ? true : false}
                    helperText={supplierErrors.name}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-lg-12">
                  <TextField
                    label="Telephone"
                    value={supplierData.telephone}
                    name="telephone"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                    error={supplierErrors.telephone ? true : false}
                    helperText={supplierErrors.telephone}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-lg-12">
                  <TextField
                    label="Email"
                    value={supplierData.email}
                    name="email"
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                    error={supplierErrors.email ? true : false}
                    helperText={supplierErrors.email}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSave()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
