import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import RequestProductsTable from "./RequestProductsTable";
//form sets init state
//update creates array of updated values

export default function RequestForm(props) {
  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Requisition {props.data.ref}</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <InputLabel>Related ABC</InputLabel>

              <Select
                name="costing_id"
                value={props.data.costing_id ? props.data.costing_id : ""}
                fullWidth
                disabled={props.type === "view"}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                required
                error={props.errors.costing_id ? true : false}
                helperText={props.errors.costing_id}
              >
                {props.costings.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {item.ref + " - " + item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Requisition Requirement"
                name="notes"
                multiline
                value={props.data.notes}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
                disabled={props.type === "view"}
                error={props.errors.notes ? true : false}
                helperText={props.errors.notes}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel>Associated Business Unit</InputLabel>
              <Select
                name="associated_bu"
                value={props.data.associated_bu ? props.data.associated_bu : ""}
                fullWidth
                inputProps={{
                  readOnly: true,
                }}
                error={props.errors.associated_bu ? true : false}
                helperText={props.errors.associated_bu}
              >
                {props.businesses.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Requested By</InputLabel>
              <Select
                name="requested_by_id"
                value={
                  props.data.requested_by_id ? props.data.requested_by_id : ""
                }
                fullWidth
                disabled={props.type === "view"}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                required
                error={props.errors.requested_by_id ? true : false}
                helperText={props.errors.requested_by_id}
              >
                {props.personnel &&
                  props.personnel.map((item, key) => {
                    return (
                      <MenuItem value={item.contact_id} key={key}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Date Requested</InputLabel>

              <TextField
                value={props.data.requested_by_date}
                name="requested_by_date"
                type="date"
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
                disabled={props.type === "view"}
                required
                error={props.errors.requested_by_date ? true : false}
                helperText={props.errors.requested_by_date}
              ></TextField>
            </div>
          </div>
        </div>
      </div>

      <div className="block" style={{ paddingBottom: 40 }}>
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Line Items</h3>
            </div>
          </div>
          <RequestProductsTable
            handleProductAdd={props.handleProductAdd}
            handleBlankProduct={props.handleBlankProduct}
            data={props.data.products}
            errors={props.errors.products}
            handleProductChange={props.handleProductChange}
            handleProductDelete={props.handleProductDelete}
            bg={props.bg}
            type={props.type}
            prodFocus={props.prodFocus}
            periodicCodes={props.periodicCodes}
          />
        </div>
      </div>
    </>
  );
}
