import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./styles.css";
import { SuppliersApi, AppButtonComponent } from "@unity/components";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductSelectForm({
  open,
  setOpen,
  bg,
  handleProductAdd,
}) {
  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [suppCat, setSuppCat] = useState("New");

  const handleClose = () => {
    setOpen({ status: false });
  };

  const getCompanies = async () => {
    const res = await SuppliersApi.getSuppliers();
    setSuppliers(res.data);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: bg,
      color: theme.palette.common.white,
      textAlign: "left",
    },
    body: {
      fontSize: 14,
      textAlign: "left",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const classes = useStyles();

  const handleSupplierSelect = async (data) => {
    const res = await SuppliersApi.getProductsForSupplier(data.id);
    setProducts(res.data);
    setSuppCat(data.approved ? "Approved" : "Pending");
  };

  const reformatData = (key) => {
    const newData = { ...products[key] };
    const theSupplier = suppliers.find(
      (e) => e.id == products[key].supplier_id
    );
    newData["product_id"] = products[key].id;
    newData["supplier_name"] = theSupplier.name;
    newData["supplier_category"] = suppCat;
    delete newData["id"];
    handleProductAdd(newData);
  };

  return (
    <div style={{ width: 500, maxWidth: "auto" }}>
      <Dialog
        open={open.status}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ maxWidth: 1000 }}>
          <Autocomplete
            id="combo-box-demo"
            options={suppliers}
            getOptionLabel={(option) =>
              option.name && typeof option.name === "string" ? option.name : ""
            }
            style={{ marginBottom: 20 }}
            onChange={(event, value) => handleSupplierSelect(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Supplier Search"
                variant="outlined"
              />
            )}
          />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Product Ref</StyledTableCell>
                  <StyledTableCell align="right">Product Name</StyledTableCell>
                  <StyledTableCell align="right">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((item, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell align="left">
                      <TextField
                        name="product_ref"
                        value={item.reference}
                        fullWidth
                        disabled
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        name="product_name"
                        value={item.name}
                        fullWidth
                        disabled
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <AppButtonComponent
                        onClick={() => reformatData(key)}
                        text="add"
                        btnIcon="add"
                        style={{ background: bg, color: "white" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
