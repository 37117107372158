import React, { useEffect, useState } from "react";
import {
  AppHeaderComponent,
  CodesApi,
  PersonnelApi,
  PurchasingApi,
} from "@unity/components";
import RequestForm from "../modules/requests/RequestForm";
import ApiLoader from "../common/ApiLoader";
import Loading from "../common/Loading";
import { useHistory } from "react-router-dom";

export default function PurchasingEdit({ route, context, location }) {
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState({});
  const [costings, setCostings] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [periodicCodes, setPCs] = useState([]);
  const [personnel, setPersonnel] = useState([]);
  const [loading, setLoading] = useState({ status: false });
  const [prodFocus, setProdFocus] = useState(false);

  let history = useHistory();

  const getRequisitionSingleData = async () => {
    const res = await PurchasingApi.getRequisitionSingle(route.match.params.id);
    setData(res.data);
  };

  const getCostings = async () => {
    const res = await PurchasingApi.getApprovedCostings();
    setCostings(res.data);
  };

  const getBusinesses = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinesses(res.data);
  };

  const getPCss = async () => {
    const res = await CodesApi.getPeriodicCodes();
    setPCs(res.data);
  };

  const getPersonnel = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const checkForErrors = () => {
    if (location.state && location.state.errs) {
      setErrors(location.state.errs);
    }
  };

  const handleSave = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });

    const res = await PurchasingApi.updateRequisition(
      route.match.params.id,
      data
    );

    setLoading({ status: true, data: "Saving, Please Wait...." });

    if (res.errors) {
      setErrors(res.errors);

      setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: "Save Success!, Please Wait...." });
      setTimeout(() => {
        history.push("/purchasing/index");
        setLoading({ status: false });
      }, 3000);
    }
  };

  const handleBlankProduct = () => {
    const newData = { ...data };
    newData["products"].push({});
    setData(newData);
  };

  const handleChange = (name, value) => {
    const newData = { ...data };
    newData[name] = value;

    if (name === "costing_id") {
      const cst = costings.find((e) => e.id === value);
      newData['costing_ref'] = cst['ref'];
      newData['costing_name'] = cst['name'];
      newData['associated_bu'] = cst['associated_bu'];
      newData['associated_bu_uuid'] = cst['associated_bu_uuid'];
      newData['associated_bu_name'] = cst['associated_bu_name'];
      newData['product_category'] = cst['product_category'];
      newData['product_category_name'] = cst['product_category_name'];
    }

    setData(newData);
    setProdFocus(false);
  };

  const handleProductChange = (name, value, key) => {
    const newData = { ...data };
    newData["products"][key][name] = value;
    setData(newData);
    setProdFocus([name, key].join("_"));
  };

  const handleProductDelete = async (key) => {
    const newData = { ...data };
    if (newData["products"][key].id) {
      await PurchasingApi.deleteProduct(newData["products"][key].id);
    }
    newData["products"].splice(key, 1);
    setData(newData);
  };

  const handleUpload = (e) => {
    const newData = { ...data };
    newData["credit_documents"] = e;
    setData(newData);
  };
  const handleProductAdd = (obj) => {
    const newData = { ...data };
    newData["products"].push(obj);
    setData(newData);
  };

  useEffect(() => {
    getRequisitionSingleData();
    getCostings();
    getBusinesses();
    getPCss();
    getPersonnel();
    checkForErrors();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="purchasing"
          subpage="edit"
        />

        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 80, paddingBottom: 40 }}>
          <RequestForm
            data={data}
            errors={errors}
            prodFocus={prodFocus}
            bg={context.theme.sidebar.background}
            handleChange={handleChange}
            handleUpload={handleUpload}
            setData={setData}
            type="edit"
            costings={costings}
            businesses={businesses}
            periodicCodes={periodicCodes}
            personnel={personnel}
            handleBlankProduct={handleBlankProduct}
            handleProductAdd={handleProductAdd}
            handleProductChange={handleProductChange}
            handleProductDelete={handleProductDelete}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
