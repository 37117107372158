import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TextField, Select, MenuItem } from "@material-ui/core";
import { AppButtonComponent } from "@unity/components";
import ProductSelectForm from "./ProductSelectForm";
import CreateSupplierForm from "./CreateSupplierForm";
import { SuppliersApi, CodesApi } from "@unity/components";
import Autocomplete from '@mui/material/Autocomplete';

export default function RequestProductsTable({
  data,
  errors,
  handleProductChange,
  handleProductDelete,
  bg,
  type,
  handleBlankProduct,
  handleProductAdd,
  prodFocus,
  periodicCodes }) {

  const [open, setOpen] = useState({ state: false });
  const [openSupplier, setOpenSupplier] = useState({ state: false });
  const [suppliers, setSuppliers] = useState([]);
  const [vat, setVAT] = useState([]);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: bg,
      color: theme.palette.common.white,
      textAlign: "left",
    },
    body: {
      fontSize: 14,
      textAlign: "left",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const classes = useStyles();

  const handleCostCalc = (key) => {
    var i = 0.0;
    var cost = parseFloat(data[key].unit_cost);
    var qty = parseInt(data[key].quantity);
    i = cost * qty;
    return i.toFixed(2);
  };

  const handleOpenSupplierForm = () => {
    setOpenSupplier({ state: true });
  };

  const getSuppliersData = async () => {
    const res = await SuppliersApi.getSuppliers();
    setSuppliers(res.data);
  };

  const getCodesData = async () => {
    const res = await CodesApi.getVatRates();
    setVAT(res.data);
  };

  const handleSupplierChange = (supplier, key) => {
    const val = supplier.approved ? "Approved" : "Pending";
    handleProductChange('supplier_name', supplier.name , key);
    handleProductChange("supplier_category", val, key);
    handleProductChange("supplier_id", supplier.id, key);
    handleProductChange(supplier.name, supplier, key);
  };

  const getSupplierValue = (supplier_id) => {
    let supplier = suppliers.find((e) => e.id === supplier_id );
    if(supplier)
    {
      return supplier;
    }
    else
    {
      return {};
    }
  };

  useEffect(() => {
    getSuppliersData();
    getCodesData();
  }, []);

  return (
    <>
      {type !== "view" && (
        <ProductSelectForm
          open={open}
          setOpen={setOpen}
          bg={bg}
          handleProductAdd={handleProductAdd}
        />
      )}
      {type !== "view" && (
        <CreateSupplierForm
          getSuppliersData={getSuppliersData}
          openSupplier={openSupplier}
          setOpenSupplier={setOpenSupplier}
          bg={bg}
        />
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Product Ref</StyledTableCell>
              <StyledTableCell align="right">Product Name</StyledTableCell>
              <StyledTableCell align="right">Supplier Category</StyledTableCell>
              <StyledTableCell align="right" style={{ width: 200 }}>Supplier</StyledTableCell>
              <StyledTableCell align="right">Unit Cost</StyledTableCell>
              <StyledTableCell align="right">Unit Qty</StyledTableCell>
              <StyledTableCell align="right">VAT Rate</StyledTableCell>
              <StyledTableCell align="right">Periodic Code</StyledTableCell>
              <StyledTableCell align="right">Duration</StyledTableCell>
              <StyledTableCell align="right">Sub Total</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, key) => {
              return (
                <StyledTableRow key={key}>
                  <StyledTableCell align="left">
                    <TextField
                      autoFocus={
                        prodFocus === "reference_".concat(key) ? true : false
                      }
                      name="reference"
                      value={item.reference}
                      onChange={(e) =>
                        handleProductChange(e.target.name, e.target.value, key)
                      }
                      fullWidth
                      disabled={type === "view"}
                      InputProps={{
                        readOnly: item.product_id ? true : false,
                      }}
                      error={errors && errors[key].reference ? true : false}
                      helperText={errors && errors[key].reference}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <TextField
                      autoFocus={
                        prodFocus === "name_".concat(key) ? true : false
                      }
                      name="name"
                      value={item.name}
                      onChange={(e) =>
                        handleProductChange(e.target.name, e.target.value, key)
                      }
                      fullWidth
                      disabled={type === "view"}
                      InputProps={{
                        readOnly: item.product_id ? true : false,
                      }}
                      error={errors && errors[key].name ? true : false}
                      helperText={errors && errors[key].name}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <TextField
                      value={
                        item.supplier_category
                          ? item.supplier_category
                          : item.approved
                          ? "Approved"
                          : "Pending"
                      }
                      name="supplier_category"
                      fullWidth
                      disabled
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Autocomplete
                      id="combo-box-demo"
                      options={suppliers}
                      getOptionLabel={(option) => option.name ? option.name : ""}
                      style={{ marginBottom: 20 }}
                      onChange={(event, value) => handleSupplierChange(value, key)}
                      value={ item.supplier_id ? getSupplierValue(item.supplier_id) : {name: "", supplier_id: null}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Supplier Search"
                          variant="outlined"
                          value={params}
                        />
                      )}
                    />
                    {!item.product_id && type !== "view" && (
                      <AppButtonComponent
                        onClick={() => handleOpenSupplierForm()}
                        btnIcon={"add"}
                        style={{
                          background: bg,
                          color: "white",
                          marginLeft: 10,
                        }}
                      />
                    )}
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <TextField
                      autoFocus={
                        prodFocus === "unit_cost_".concat(key) ? true : false
                      }
                      type="number"
                      name="unit_cost"
                      value={item.unit_cost}
                      onChange={(e) =>
                        handleProductChange(e.target.name, e.target.value, key)
                      }
                      fullWidth
                      disabled={type === "view"}
                      error={errors && errors[key].unit_cost ? true : false}
                      helperText={errors && errors[key].unit_cost}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TextField
                      autoFocus={
                        prodFocus === "quantity_".concat(key) ? true : false
                      }
                      type="number"
                      name="quantity"
                      value={item.quantity}
                      onChange={(e) =>
                        handleProductChange(e.target.name, e.target.value, key)
                      }
                      fullWidth
                      disabled={type === "view"}
                      error={errors && errors[key].quantity ? true : false}
                      helperText={errors && errors[key].quantity}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Select
                      value={item.vat_rate ? item.vat_rate : ""}
                      name="vat_rate"
                      onChange={(e) =>
                        handleProductChange(e.target.name, e.target.value, key)
                      }
                      fullWidth
                      disabled={type === "view"}
                      error={errors && errors[key].vat_rate ? true : false}
                      helperText={errors && errors[key].vat_rate}
                    >
                      {vat.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Select
                      value={item.periodic_code ? item.periodic_code : ""}
                      name="periodic_code"
                      onChange={(e) =>
                        handleProductChange(e.target.name, e.target.value, key)
                      }
                      fullWidth
                      disabled={type === "view"}
                      error={errors && errors[key].periodic_code ? true : false}
                      helperText={errors && errors[key].periodic_code}
                    >
                      {periodicCodes.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TextField
                        autoFocus={
                          prodFocus === "duration_".concat(key) ? true : false
                        }
                        type="number"
                        name="duration"
                        value={item.duration}
                        onChange={(e) =>
                          handleProductChange(e.target.name, e.target.value, key)
                        }
                        fullWidth
                        disabled={type === "view"}
                        error={errors && errors[key].duration ? true : false}
                        helperText={errors && errors[key].duration}
                      />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {handleCostCalc(key)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <AppButtonComponent
                      onClick={() => handleProductDelete(key)}
                      btnIcon="delete"
                      style={{
                        background: "red",
                        color: "white",
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {type !== "view" && (
        <AppButtonComponent
          onClick={() => setOpen({ status: true })}
          text="add existing product"
          btnIcon="add"
          style={{
            background: bg,
            color: "white",
            marginTop: 20,
            marginRight: 15,
          }}
        />
      )}
      {type !== "view" && (
        <AppButtonComponent
          onClick={() => handleBlankProduct()}
          text="add blank product"
          btnIcon="add"
          style={{ background: bg, color: "white", marginTop: 20 }}
        />
      )}
    </>
  );
}
