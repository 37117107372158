import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { AppHeaderComponent, AppButtonComponent, PurchasingApi } from "@unity/components";
import { CanDelete, CanModuleAdmin, CanUpdate } from "../services/Permissions";
import AppButton from "../common/AppButton";
import Loading from "../common/Loading";
import FilterTable from "../common/FilterTable";
import FilterTableWithDialog from "../common/FilterTableWithDialog";
import ComponentTabs from "../common/Tabs";
import RequestSettings from "../modules/requests/RequestSettings";

export default function Purchasing({ route, history, context }) {
  const [data, setData] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [captureState, setCaptureState] = useState({ id: null, type: null, reason: null });

  const edit = CanUpdate("purchasing");
  const del = CanDelete("purchasing");
  const admin = CanModuleAdmin("purchasing");

  let newHistory = useHistory();
console.log("DATA: ",data);

  const purchase_requests_columns = [
    { name: "reference", label: "Reference No" },
    { name: "author_name", label: "Created By" },
    { name: "costing_ref", label: "ABC Ref#" },
    { name: "costing_name", label: "ABC Name" },
    { name: "status", label: "Status" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {

          return (
            <>
              <Link
                to={{
                  pathname: `/purchasing/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="View"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              {edit && !data[dataIndex].approved && (
                <Link
                  to={{
                    pathname: `/purchasing/edit/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}

              {edit && !data[dataIndex].requested_approval && (
                <AppButton
                  text="request approval"
                  btnIcon="edit"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 10,
                  }}
                  onClick={() => requestPurchaseApproval(data[dataIndex].id)}
                />
              )}

              {edit &&
                data[dataIndex].requested_approval &&
                !data[dataIndex].approved && (
                  <AppButton
                    text="cancel request"
                    btnIcon="close"
                    onClick={() => requestPurchaseApproval(data[dataIndex].id)}
                    style={{
                      background: "red",
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                )}

              {admin &&
                data[dataIndex].requested_approval &&
                !data[dataIndex].rejected && (
                  <AppButton
                    text={data[dataIndex].approved ? "cancel" : "reject"}
                    btnIcon="close"
                    style={{
                      background: "red",
                      color: "white",
                      marginLeft: 10,
                    }}
                    onClick={() => handleOpenReason(data[dataIndex])}
                    // onClick={() => handleRejectRequest(data[dataIndex].id)}
                  />
                )}

              {admin &&
                data[dataIndex].requested_approval &&
                !data[dataIndex].approved && (
                  <AppButton
                    text="approve"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                    onClick={() => handleApproveRequest(data[dataIndex].id)}
                  />
                )}

              {edit &&
                data[dataIndex].approved && 
                !data[dataIndex].rejected && (
                  <AppButton
                    text="duplicate"
                    btnIcon="copy_all"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                    onClick={() => handleDuplicateRequest(data[dataIndex].id)}
                  />
                )}
            </>
          );
        },
      },
    },
  ];

  const purchasing_columns = [
    {
      name: "",
      label: "Requisition Ref#",
      options: {
        customBodyRenderLite: (dataIndex) => {

          return (
            <>
              <Link to={'/purchasing/view/' + data[dataIndex].requisition_id}>{data[dataIndex].requisition_ref}</Link>
            </>
          );
        },
      },
    },
    { name: "reference", label: "PO Ref#" },
    { name: "costing_ref", label: "ABC Ref#" },
    {
      name: "",
      label: "Supplier Name",
      options: {
        customBodyRenderLite: (dataIndex) => {

          return (
            <>
              <Link to={'/suppliers/edit/' + data[dataIndex].supplier_id}>{data[dataIndex].supplier_name}</Link>
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "Supplier Email",
      options: {
        customBodyRenderLite: (dataIndex) => {

          return (
            <a href={'mailto:' + data[dataIndex].supplier_email }>{data[dataIndex].supplier_email}</a>
          );
        },
      },
    },
    { name: "requested_by_date", label: "Date Requested" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {

          return (
            <>
              <AppButton
                text="download"
                btnIcon="download"
                style={{
                  background: context.theme.sidebar.background,
                  color: "white",
                }}
                onClick={() => handleDownload(data[dataIndex].pdf_id)}
              />
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: "none",
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {
        // PurchasingApi delete function here!
      });
    },
  };

  const tabs = [
    { icon: "check", label: "Purchase Requests" },
    { icon: "check", label: "Purchase Orders" },
  ];

  if (CanModuleAdmin("purchasing")) {
    tabs.push({ icon: "settings", label: "Module Settings" });
  }


  const requestPurchaseApproval = async (id) => {
    const res = await PurchasingApi.requestRequisitionApproval(id, {});

    if (res.errors) {
      newHistory.push({
        pathname: `/purchasing/edit/${id}`,
        state: {
          errs: res.errors,
        },
      });
    } else {
      getPurchaseIndex();
    }
  };

  const handleApproveRequest = async (id) => {
    const res = await PurchasingApi.approveRequisition(id, {});
    getPurchaseIndex();
  };

  const handleDuplicateRequest = async (id) => {
    const res = await PurchasingApi.duplicateRequisition(id, {});
    getPurchaseIndex();
  };

  const handleRejectRequest = async (id) => {
    setOpen(false);
    const res = await PurchasingApi.rejectRequisition(id, captureState);
    getPurchaseIndex();
    setCaptureState({ id: null, type: null, reason: null });
  };

  const handleDownload = async (id) => {
    const res = await PurchasingApi.downloadDocument(id);
    var win = window.open(res.data, "_blank");
    win.focus();
  };

  const getPurchaseIndex = async () => {
    const res = await PurchasingApi.getRequisitions();
    setData(res.data);
  };
  
  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      setData(false);
      const res = await PurchasingApi.getRequisitions();
      setData(res.data);
    } else if (newValue === 1) {
      setData(false);
      const res = await PurchasingApi.getPurchaseOrders();
      setData(res.data);
    }
  };

  const handleOpenReason = (obj) => {
    setOpen(true);
    setCaptureState({
      id: obj.id,
      type: obj.approved ? "cancellation" : "rejection",
    });
  };


  useEffect(() => {
    getPurchaseIndex();
  }, []);

  
  

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="purchasing"
          addNew={() => (
            <Link to={"/purchasing/create"}>
              <AppButtonComponent
                text="Add New"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
        />

        <ComponentTabs
          tabs={tabs}
          value={tabValue}
          setValue={setTabValue}
          handleChange={handleChange}
        />

        <div style={{ paddingTop: 166 }}>
          {tabValue === 0 && (
            <>
              {data ? (
                  
                  <FilterTableWithDialog
                    open={open}
                    setOpen={setOpen}
                    captureState={captureState}
                    setCaptureState={setCaptureState}
                    accept={handleRejectRequest}
                    columns={purchase_requests_columns}
                    data={data}
                    options={options}
                  />
              ) : (
                <Loading />
              )}
            </>
          )}
          {tabValue === 1 && (
            <>
              {data ? (
                <FilterTable
                  columns={purchasing_columns}
                  data={data}
                  options={options}
                />
              ) : (
                <Loading />
              )}
            </>
          )}
          {tabValue === 2 && <RequestSettings context={context} />}
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
