import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TextField } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InputCaptureForm({
  open,
  setOpen,
  captureState,
  setCaptureState,
  accept
}) {
  
  console.log("open",open);
  console.log("captureState",captureState);

  const handleInputChange = (e) => {
    setCaptureState({ id: captureState.id, type: captureState.type, reason: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);
    

  return (
    <div style={{ width: 1000, maxWidth: "auto" }}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Enter {captureState.type} reason:</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <TextField
                name="reason"
                label="reason"
                fullWidth
                multiline
                rows={4}
                value={captureState.reason ? captureState.reason : ""}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => accept(captureState.id)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
