import React, { useState, useEffect } from "react";
import {
  AppHeaderComponent,
  PurchasingApi,
  CodesApi,
  PersonnelApi,
} from "@unity/components";
import RequestForm from "../modules/requests/RequestForm";
import ApiLoader from "../common/ApiLoader";
import { useHistory } from "react-router-dom";
import Loading from "../common/Loading";

export default function PurchasingCreate({ route, context }) {
  const [data, setData] = useState({ products: [] });
  const [loading, setLoading] = useState({ status: false });
  const [errors, setErrors] = useState({});
  const [costings, setCostings] = useState([]);
  const [businesses, setBusinesses] = useState(false);
  const [periodicCodes, setPCs] = useState([]);
  const [personnel, setPersonnel] = useState(false);
  const [prodFocus, setProdFocus] = useState(false);

  let history = useHistory();

  const getCostings = async () => {
    const res = await PurchasingApi.getApprovedCostings();
    setCostings(res.data);
  };

  const getBusinesses = async () => {
    const res = await CodesApi.getBusinessUnits();
    setBusinesses(res.data);
  };

  const getPCss = async () => {
    const res = await CodesApi.getPeriodicCodes();
    setPCs(res.data);
  };

  const getPersonnel = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const handleSave = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });

    const x = await PurchasingApi.createRequisition(data);

    setLoading({ status: true, data: "Saving, Please Wait...." });

    if (x.errors) {
      setErrors(x.errors);

      console.log( "errors", x.errors );
      console.log( "data that caused the errors", data );

      setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: "Save Success!, Please Wait...." });
      setTimeout(() => {
        history.push("/purchasing/index");
        setLoading({ status: false });
      }, 3000);
    }
  };

  const handleChange = (name, value) => {
    const newData = { ...data };
    newData[name] = value;

    if (name === "costing_id") {
      const cst = costings.find((e) => e.id === value);
      newData['costing_ref'] = cst['ref'];
      newData['costing_name'] = cst['name'];
      newData['associated_bu'] = cst['associated_bu'];
      newData['associated_bu_uuid'] = cst['associated_bu_uuid'];
      newData['associated_bu_name'] = cst['associated_bu_name'];
      newData['product_category'] = cst['product_category'];
      newData['product_category_name'] = cst['product_category_name'];
    }

    setData(newData);
    setProdFocus(false);
  };

  const handleUpload = (e) => {
    const newData = { ...data };
    newData["credit_documents"] = e;
    setData(newData);
  };

  const handleBlankProduct = () => {
    const newData = { ...data };
    newData["products"].push({});
    setData(newData);
  };

  const handleProductChange = (name, value, key) => {
    const newData = { ...data };
    newData["products"][key][name] = value;
    setData(newData);
    setProdFocus([name, key].join("_"));
  };

  const handleProductAdd = (obj) => {
    const newData = { ...data };
    newData["products"].push(obj);
    setData(newData);
  };

  const handleProductDelete = async (key) => {
    const newData = { ...data };
    newData["products"].splice(key, 1);
    setData(newData);
  };

  useEffect(() => {
    getCostings();
    getBusinesses();
    getPCss();
    getPersonnel();
  }, []);

  if (businesses && personnel) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="purchasing"
          subpage="create"
        />
        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 80, paddingBottom: 40 }}>
          <RequestForm
            data={data}
            errors={errors}
            prodFocus={prodFocus}
            handleChange={handleChange}
            handleUpload={handleUpload}
            bg={context.theme.sidebar.background}
            type="create"
            setData={setData}
            costings={costings}
            personnel={personnel}
            businesses={businesses}
            periodicCodes={periodicCodes}
            handleBlankProduct={handleBlankProduct}
            handleProductAdd={handleProductAdd}
            handleProductChange={handleProductChange}
            handleProductDelete={handleProductDelete}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
