import { MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { PurchasingApi, PersonnelApi } from "@unity/components";
import AppButton from "../../common/AppButton";

export default function JobCostingSettings({ context }) {
  const [data, setData] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [personnel, setPersonnel] = useState([]);

  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const getData = async () => {
    const notifications = await PurchasingApi.getNotifications();
    console.log(notifications);
    setData({
      notifications: [...notifications.data],
    });
  };

  useEffect(() => {
    getData();
    getPersonnelData();
  }, []);

  //Notifications

  const handleAddNotification = () => {
    const newData = { ...data };
    newData["notifications"].push({});
    setData(newData);
  };

  const handleNotificationAddCancel = (key) => {
    const newData = { ...data };
    newData["notifications"].splice(key, 1);
    setData(newData);
  };

  const handleSaveNotificationSingle = async (key) => {
    await PurchasingApi.saveNotifications(data["notifications"]);
    setEditActive(false);
  };

  const handleNewUser = (contact_id, key) => {
    const newData = { ...data };
    const userObj = personnel.find((e) => e.contact_id === contact_id);
    newData["notifications"][key]["contact_id"] = contact_id;
    newData["notifications"][key]["name"] = userObj.name;
    newData["notifications"][key]["role"] = userObj.role;
    setData(newData);
  };

  const handleNotificationUserDelete = (key) => {
    const newData = { ...data };
    newData["notifications"].splice(key, 1);
    setData(newData);
  };

  if (data) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="block" style={{ padding: 15 }}>
              <h6 style={{ margin: 0 }}>Receive Notifications</h6>
              <sub>
                Who within this system will receive notifications when
                requisitions are flagged for approval, or when purchase orders
                are marked as all received.{" "}
              </sub>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: 20,
                  flexWrap: "wrap",
                  flexDirection: "row",
                  flexGrow: "inherit",
                }}
              >
                {data.notifications.map((item, key) => {
                  return (
                    <div style={{ flex: 4, maxWidth: 350 }}>
                      <div
                        style={{
                          display: "flex",
                          width: 350,
                          marginTop: 20,
                          marginBottom: 20,
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {item.contact_id ? (
                          <>
                            <div style={{ flex: 1 }}>
                              <img
                                className="profile"
                                src={
                                  context.profile
                                    ? context.profile
                                    : "https://www.gravatar.com/avatar/94d093eda664addd6e450d7e9881bcad?s=32&d=identicon&r=PG"
                                }
                                alt="Profile"
                              />
                            </div>
                            <div style={{ flex: 2 }}>
                              <div>{item.name}</div>
                              <div>{item.role}</div>
                            </div>
                            <div style={{ flex: 1 }}>
                              {editActive === "notifications" && (
                                <AppButton
                                  onClick={() =>
                                    handleNotificationUserDelete(key)
                                  }
                                  btnIcon="delete"
                                  style={{
                                    marginTop: 20,
                                    marginRight: 15,
                                    background: "red",
                                    color: "white",
                                  }}
                                />
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ flex: 1, paddingRight: 25 }}>
                              <Select
                                fullWidth
                                value={item.contact_id}
                                onChange={(e) =>
                                  handleNewUser(e.target.value, key)
                                }
                              >
                                {personnel.map((item, key) => {
                                  return (
                                    <MenuItem key={key} value={item.contact_id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </div>
                            <div style={{ flex: 4 }}>
                              <AppButton
                                onClick={() => handleNotificationAddCancel(key)}
                                btnIcon="close"
                                style={{
                                  marginTop: 20,
                                  marginRight: 15,
                                  background: "red",
                                  color: "white",
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {editActive === "notifications" ? (
                <>
                  <AppButton
                    onClick={() => setEditActive(false)}
                    text="cancel"
                    btnIcon="close"
                    style={{
                      marginTop: 20,
                      marginRight: 15,
                      background: "red",
                      color: "white",
                    }}
                  />
                  <AppButton
                    onClick={() => handleAddNotification()}
                    text="add"
                    btnIcon="add"
                    style={{
                      marginTop: 20,
                      marginRight: 15,
                      background: context.theme.sidebar.background,
                      color: "white",
                    }}
                  />
                  <AppButton
                    onClick={() => handleSaveNotificationSingle()}
                    text="save changes"
                    btnIcon="save"
                    style={{
                      marginTop: 20,
                      marginRight: 15,
                      background: "green",
                      color: "white",
                    }}
                  />
                </>
              ) : (
                <AppButton
                  onClick={() => setEditActive("notifications")}
                  text="edit"
                  btnIcon="edit"
                  style={{
                    marginTop: 20,
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <p>Loading</p>;
  }
}
